.WorkshopAddToCalendarBlock {
  &--Container {
    margin-bottom: 5px;
  }

  &--Dropdown {
    display: block !important;
    margin-right: 0.25em;
    margin-bottom: 0.25em;

    & > .menu {
      right: 0;
      @media (max-width: 768px) {
        position: relative !important;
      }

      & > .item {
        text-align: center !important;
      }
    }

    & > .dropdown.icon {
      display: none;
    }
  }

  &--Modal {
    padding-bottom: 10px;
  }
}