@keyframes slide {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-50%);
    }
}

.accordion-footer {
    z-index: 1000;
    position: fixed;
    height: 80px;
    text-align: center;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.open {
        transition: transform ease-in-out 0.3s;
        transform: translateY(0);
    }
    &.closed {
        transition: transform ease-in-out 0.3s;
        transform: translateY(110%);
    }

    &.close {
        width: max-content;
        margin: auto;
        padding: 0px 20px;
        cursor: pointer;
        height: 30px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .trapezoid {
        position: relative;
        font-weight: bold;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        font-size: 1.4em;
        clip-path: polygon(5% 0%, 95% 0%, 100% 100%, 0% 100%);

        .close {
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 4px;
        }
    }

    .items__wrapper {
        display: inline-block;
        height: 40px;
        padding-top: 12px;
        white-space: nowrap;
        animation: slide 20s linear infinite;
    }

    .items__wrapper__100vw {
        display: inline-block;
        min-width: 100vw;
        margin: 0 auto;
        white-space: nowrap;
    }

    .items {
        width: 100%;
        margin-left: 0px;
        overflow: hidden;

        .item {
            display: inline-block;
            font-size: 16px;

            .point {
                font-size: 16px;
                padding: 0px 20px;
            }
        }
    }
}

@media (max-width: 768px) {
    .accordion-footer {
        .trapezoid {
            width: 90%;
        }
    }
}
