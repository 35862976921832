
.item--workshop {

  i.icon {
    line-height: 20px;
  }

  .header {
    font-size: 14px !important;
  }

  .start-date {
    font-size: 11px;
    text-transform: uppercase // font-weight: bold;
  }

  .end-date, .timezone {
    font-size: 10px;
    text-transform: uppercase
  }

}