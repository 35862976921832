.WorkshopsRegistrationBlock {
    &.image {
        margin: 10px 0px;
        width: 100%;

        .Workshop {
            width: 100%;
            border-radius: 4px;
            box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);

            &__Container {
                display: flex;

                &--Image {
                    margin-right: 20px;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 300px;
                    min-height: 200px;
                }

                &--Content {
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    .title {
                        font-size: 2em;
                    }

                    .date {
                        margin-bottom: 1em;
                    }

                    .description {
                        font-size: 1.2em;
                    }

                    .speakers {
                        margin-top: 10px;
                    }

                    .actions {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        .see-more-button {
                            margin-left: 5px;
                        }
                    }
                }

            }

        }
    }
}

@media (min-width: 320px) and (max-width: 768px) {
    .WorkshopsRegistrationBlock {
        &.image {
            .Workshop {
                &__Container {
                    display: block;

                    &--Image {
                        margin-right: 0px;
                        margin-bottom: 10px;
                        width: 100%;
                        max-width: 100%;
                        padding-bottom: 56.25%;
                    }

                    &--Content {
                        .actions {
                            justify-content: center;
                        }
                    }

                }
            }

        }
    }
}