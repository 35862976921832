.ctaTile--block > a.segment {
  background: rgba(255, 255, 255, 0.9);
}
.ctaTile--block > a.segment:not(.segment--background-image):hover {
  background: rgba(255, 255, 255, 1);
}
.ctaTile--block>.ui.segment {
  padding: 20px;
}

.ui.segment.segment--background-image {
  background-position: center;
  background-size: cover;

  .ui.header {
    color: rgba(255, 255, 255, 0.9);

    .sub.header {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}
