.block--logos {
  .ui.card {
    background: transparent;
    box-shadow: none;
    justify-content: center;

    .ui.segment {
      border-radius: 0 !important;
    }
  }
}